import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalTop from "../components/globalTop"

const Blog = ({ blogPosts }) => (
  <Layout>
    <SEO title="Blog" />

    <GlobalTop title="Blog" />

    <section className="blog-content">
      <div className="container">
        <div className="row">
          {blogPosts.edges.map(({ node }) => (
            <div className="col-xl-4 col-sm-6" key={node.id}>
              <Link to={`/blog/${node.slug}`} className="blog__tile">
                <div
                  className="blog__tile-thumb"
                  style={{
                    backgroundImage: `url(${
                      node.featuredImage
                        ? node.featuredImage.node.sourceUrl
                        : ""
                    })`,
                  }}
                ></div>
                <div className="blog__tile-info">
                  <span className="user">Agnieszka Kaszuba</span>
                </div>
                <h4>{node.title}</h4>
                <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  </Layout>
)

export default () => (
  <StaticQuery
    query={graphql`
      {
        blogPosts: allWpPost {
          edges {
            node {
              id
              title
              slug
              excerpt
              featuredImage {
                node {
                  id
                  sourceUrl
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Blog {...data} />}
  />
)
